<template>
    <div class="class">
        <el-affix :offset="55">
            <header>
                <span class="head-but" @click="cancle"><el-button plain :icon="ArrowLeft">返回</el-button></span>
                <span class="head-t">{{ title }}</span>
            </header>
        </el-affix>
        <el-form class="form" ref="ruleForm" :model="formData" :rules="rules" label-width="150px">
            <el-col class="audit-title">
                <span class="text">基本信息</span>
            </el-col>
            <el-row>
                <el-col :span="16">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="年度：" prop="achievementYear" :label-width="formLabelWidth">
                                <el-date-picker
                                    v-model="formData.achievementYear"
                                    type="year"
                                    placeholder="请选择"
                                    :size="size"
                                    value-format="YYYY"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="类别：" prop="achievementType" :label-width="formLabelWidth">
                                <el-select
                                    filterable
                                    v-model="formData.achievementType"
                                    class="search-input"
                                    placeholder="请选择"
                                >
                                    <el-option
                                        v-for="(item, index) in statusMap"
                                        :key="index"
                                        :label="item.label"
                                        :value="item.label"
                                    />
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col v-if="formData.achievementType === '其他'" :span="16">
                    <el-row>
                        <el-col :span="12"> </el-col>
                        <el-col :span="12">
                            <el-form-item label="" prop="achievementTypeName" :label-width="formLabelWidth">
                                <el-input v-model="formData.achievementTypeName"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="16">
                    <el-form-item label="标题：" prop="achievementTitle" :label-width="formLabelWidth">
                        <el-input placeholder="请输入" v-model="formData.achievementTitle"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="16">
                    <el-form-item label="问题附件：" prop="filePath" required="true" :label-width="formLabelWidth">
                        <AddFile @putUrl="putUrl" :fileList="formData.filePath || []" action="/common/upload" />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <el-row :span="16" class="footer">
            <el-button @click="cancle">返 回</el-button>
            <el-button type="primary" @click="submit(ruleForm)">保存</el-button>
        </el-row>
    </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, defineEmits, defineProps, getCurrentInstance } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import AddFile from '@/components/addFile.vue';
import AuditRaecords from '@/components/auditRaecords';
import TaskInfo from '@/views/proClassification/components/taskInfo';
import { formatDateTime } from '@/utils/common';
let store = useStore();
let userRole = ref(false);
const { proxy } = getCurrentInstance();
let rules = {
    achievementYear: { required: true, message: '请选择', trigger: 'blur' },
    achievementType: { required: true, message: '请选择', trigger: 'blur' },
    achievementTypeName: { required: true, message: '请输入', trigger: 'blur' },
    achievementTitle: { required: true, message: '请输入', trigger: 'blur' },
    filePath: { required: true, message: '请上传附件', trigger: 'blur' },
};
let roles = store.state.user.roles;
function isDisables(role) {
    let res = true;
    if (title.value == '编辑指标') {
        role.forEach(e => {
            if (roles.indexOf(e) > -1) {
                res = false;
            }
            return res;
        });
    } else {
        res = false;
    }
    return res;
}
const checkedCities = ref(['初发偶发', '多发问题']);
const cities = ['初发偶发', '多发问题', '突发问题', '历史遗留', '多跨疑难', '一般性问题'];
const router = useRouter();
let formLabelWidth = '150px';

const statusMap = [
    {
        value: '1',
        label: '理论',
    },
    {
        value: '2',
        label: '制度',
    },
    {
        value: '3',
        label: '规范',
    },
    {
        value: '4',
        label: '标准',
    },
    {
        value: '5',
        label: '其他',
    },
];
let tableData = ref([
    {
        name: '通过',
    },
]);
let formData = ref({});
let frontIndexData = ref({});
let ruleForm = ref(null);
let showTable = ref(false); //表格展示
let allDisabled = ref(false); //查看所有input禁用和按钮不展示
let emit = defineEmits();
// let props = defineProps(['formData', 'title']);
// const { formData, title } = props;
// console.log(formData, title);
const route = useRoute();
let title = ref('');
let date = new Date();
const tab = route.query.title == '填报' ? 1 : 2;
const form = ref({
    writeTime: formatDateTime(new Date()),
    filePath: [],
});
console.log(form.value);
// 表单数据初始化
function init() {
    console.log(route);
    title.value = route.query.title;
    form.value.unitId = route.query.id;
    if (route.query.title == '修改理论制度成果') {
        getTaskById();
    }
    // type为判断条件，1审核和指标查看，2是已审核重大任务查看，3是已审核指标查看
    // if (route.query.type) {
    //     showTable.value = true;
    // }
}

const submit = ruleForm => {
    ruleForm.validate((valid, fields) => {
        if (!valid) {
            return;
        }
        let newForm = Object.assign({}, formData.value);
        newForm.fileUrl = formData.value.filePath.map(e => e.url).join(',');
        newForm.fileName = formData.value.filePath.map(e => e.name).join(',');
        if (formData.value.achievementType === '其他') {
            newForm.achievementType = formData.value.achievementTypeName;
        }
        if (route.query.title == '修改理论制度成果') {
            proxy.$api.dataMaintenance.update(newForm).then(res => {
                if (res.code == 200) {
                    ElMessage.success('操作成功');
                    router.push({
                        name: 'dataMaintenance',
                        query: {
                            tab: tab,
                        },
                    });
                } else {
                    ElMessage.error(res.msg);
                }
            });
        } else {
            proxy.$api.dataMaintenance.add(newForm).then(res => {
                if (res.code == 200) {
                    ElMessage.success('操作成功');
                    router.push({
                        name: 'dataMaintenance',
                        query: {
                            tab: tab,
                        },
                    });
                } else {
                    ElMessage.error(res.msg);
                }
            });
        }
    });
};

const getTaskById = () => {
    //根据id获取任务详情
    proxy.$api.dataMaintenance.getById(route.query.id).then(res => {
        if (res.code == 200) {
            formData.value = res.data;
            // form.value.writeTime = res.data.writeTime;
            // form.value.completeNum = res.data.completeNum;
            // form.value.doneFlag = res.data.doneFlag;
            formData.value.filePath = res.data.filePath;
        }
    });
};

function putUrl(url) {
    console.log(url, form.value.filePath);
    formData.value.filePath = url;
}
let cancle = () => {
    router.push({
        name: 'dataMaintenance',
        query: {
            tab: tab,
        },
    });
};
onMounted(() => {
    console.log('userinfo', store.state.user);
    init();
});
</script>

<style scoped lang="stylus">
.class
    // height: calc(100% - 32px) !important
    height calc(100% - 32px)
    background: #fff
    header
        // width: 1200px;
        height: 56px;
        line-height: 56px
        text-align: center
        background: #FFFFFF;
        box-shadow: 0 0 10px -6px rgba(0,0,0,0.30);
        border-radius: 4px;
        padding-left 24px
        border-bottom: 1px solid #EAEAEA
        .head-but
            float left
        .head-t
            font-family: PingFangSC-Medium;
            font-weight: 500;
            font-size: 18px;
            color: #333333;
    .form
        padding 24px 24px 25px
        background: #FFFFFF;
        .footer
            height: 64px;
            justify-content: center
            border-top 1px solid #EEEEEE
            align-items: center !important
            /deep/ .el-form-item
                margin-bottom 0
        .milestone
            width 100%
            height 100%
    .title
        text-align: left
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 16px
    .audit-title
        display: flex
        justify-content: space-around
        font-family: PingFangSC-S0pxibold;
        font-size: 16px;
        color: #333333;
        .text
            width 66%
            margin-bottom 16px
            font-weight: 600;

    .add-unit
        margin-bottom 24px
        color: #1492FF;
        text-align center
    .unit-input
        position relative
    .icon
        width: 16px;
        height: 16px;
        position absolute
        top 8px
        right -25px
        background: url('~@/assets/img/delete.png') no-repeat
    .tagertNum
        /deep/ .el-form-item__content
            flex-wrap: nowrap
            justify-content: space-between
/deep/ .el-cascader
    width 39.2vw
/deep/ .el-select
    width 35vw
/deep/ .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf
    border-bottom: 0px solid #fff
/deep/ .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf
    border-bottom: 0px solid #fff
/deep/ .el-row
    justify-content center
</style>
